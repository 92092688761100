export const dateMonth = [
    { "value": "01", "label": "January" },
    { "value": "02", "label": "February" },
    { "value": "03", "label": "March" },
    { "value": "04", "label": "April" },
    { "value": "05", "label": "May" },
    { "value": "06", "label": "June" },
    { "value": "07", "label": "July" },
    { "value": "08", "label": "August" },
    { "value": "09", "label": "September" },
    { "value": "10", "label": "October" },
    { "value": "11", "label": "November" },
    { "value": "12", "label": "December" }
];

export const countryData =[
    { "value": "AF", "name": "Afghanistan" },
    { "value": "AM", "name": "Armenia" },
    { "value": "AZ", "name": "Azerbaijan" },
    { "value": "BH", "name": "Bahrain" },
    { "value": "BD", "name": "Bangladesh" },
    { "value": "BT", "name": "Bhutan" },
    { "value": "BN", "name": "Brunei" },
    { "value": "KH", "name": "Cambodia" },
    { "value": "CN", "name": "China" },
    { "value": "TL", "name": "East Timor" },
    { "value": "PH", "name": "Philippines" },
    { "value": "GE", "name": "Georgia" },
    { "value": "IN", "name": "India" },
    { "value": "ID", "name": "Indonesia" },
    { "value": "IR", "name": "Iran" },
    { "value": "IQ", "name": "Iraq" },
    { "value": "IL", "name": "Israel" },
    { "value": "JP", "name": "Japan" },
    { "value": "JO", "name": "Jordan" },
    { "value": "KZ", "name": "Kazakhstan" },
    { "value": "KW", "name": "Kuwait" },
    { "value": "KG", "name": "Kyrgyzstan" },
    { "value": "LB", "name": "Lebanon" },
    { "value": "LY", "name": "Libya" },
    { "value": "MY", "name": "Malaysia" },
    { "value": "MA", "name": "Morocco" },
    { "value": "OM", "name": "Oman" },
    { "value": "PK", "name": "Pakistan" },
    { "value": "PS", "name": "Palestine" },
    { "value": "QA", "name": "Qatar" },
    { "value": "SA", "name": "Saudi Arabia" },
    { "value": "SY", "name": "Syria" },
    { "value": "TN", "name": "Tunisia" },
    { "value": "TR", "name": "Turkey" },
    { "value": "AE", "name": "United Arab Emirates" },
    { "value": "YE", "name": "Yemen" }
  ]
  
