const Linkedin = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      fill="#ffb900"
    >
      <g>
        <path
          style={{ fill: "#ffb900" }}
          d="M119.18,164.715H16.396c-4.562,0-8.258,3.698-8.258,8.258v330.204c0,4.562,3.696,8.258,8.258,8.258   H119.18c4.562,0,8.258-3.696,8.258-8.258V172.973C127.438,168.413,123.742,164.715,119.18,164.715z"
        />
        <path
          style={{ fill: "#ffb900" }}
          d="M67.825,0.563C30.426,0.563,0,30.956,0,68.314c0,37.374,30.426,67.779,67.825,67.779   c37.369,0,67.771-30.406,67.771-67.779C135.597,30.956,105.195,0.563,67.825,0.563z"
        />
        <path
          style={{ fill: "#ffb900" }}
          d="M380.621,156.508c-41.282,0-71.799,17.747-90.309,37.911v-21.446c0-4.56-3.696-8.258-8.258-8.258   H183.62c-4.562,0-8.258,3.698-8.258,8.258v330.204c0,4.562,3.696,8.258,8.258,8.258h102.56c4.562,0,8.258-3.696,8.258-8.258   V339.803c0-55.053,14.954-76.501,53.331-76.501c41.796,0,45.117,34.383,45.117,79.334v160.543c0,4.562,3.696,8.258,8.258,8.258   h102.598c4.562,0,8.258-3.696,8.258-8.258V322.056C512,240.194,496.391,156.508,380.621,156.508z"
        />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
export default Linkedin;
